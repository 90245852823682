import { fetchAllFormsAPI } from "@/api/getAllForms";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Form {
  id: number;
  title: string;
  responses: number;
  completion: number;
  updated: string;
  created: string;
}

interface AllFormsState {
  forms: Form[];
  loading: boolean;
}

const initialState: AllFormsState = {
  forms: [],
  loading: false,
};

// Async thunk to fetch forms
export const fetchAllForms = createAsyncThunk<Form[]>("allForms/fetchAllForms", async () => {
  const response = await fetchAllFormsAPI();
  return response;
});

const allFormsSlice = createSlice({
  name: "allForms",
  initialState,
  reducers: {
    addForm: (state, action: PayloadAction<Form>) => {
      state.forms.push(action.payload);
    },
    deleteForm: (state, action: PayloadAction<number>) => {
      state.forms = state.forms.filter((form) => form.id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllForms.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllForms.fulfilled, (state, action: PayloadAction<Form[]>) => {
        state.loading = false;
        state.forms = action.payload;
      })
      .addCase(fetchAllForms.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { addForm, deleteForm } = allFormsSlice.actions;

export default allFormsSlice.reducer;
