import Navbar from "@/components/navbar";
import { Toaster } from "@/components/ui/sonner";

import React from "react";
import { Outlet, useLocation } from "react-router-dom";

const Layout: React.FC = () => {
  const location = useLocation();
  return (
    <div className="grid grid-rows-[auto,1fr] h-screen p-4">
      <Navbar location={location.pathname} />
      <div className="mt-4 row-span-2">
        <Outlet />
      </div>
      <Toaster />
    </div>
  );
};

export default Layout;
