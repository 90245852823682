import { ContentType, CountryCode } from "@/common/types";
import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

// Utility function for debouncing
export function debounce(func: (...args: any[]) => void, wait: number) {
  let timeout: ReturnType<typeof setTimeout>;
  return (...args: any[]) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
}

export function contentTypeToLabel(contentType: ContentType): string {
  switch (contentType) {
    case "contact-info":
      return "Contact Info";
    case "email":
      return "Email";
    case "phone-number":
      return "Phone Number";
    case "address":
      return "Address";
    case "website":
      return "Website";
    case "multiple-choice":
      return "Multiple Choice";
    case "dropdown":
      return "Dropdown";
    case "picture-choice":
      return "Picture Choice";
    case "yes-no":
      return "Yes/No";
    case "legal":
      return "Legal";
    case "net-promoter-score":
      return "Net Promoter Score";
    case "opinion-scale":
      return "Opinion Scale";
    case "rating":
      return "Rating";
    case "ranking":
      return "Ranking";
    case "matrix":
      return "Matrix";
    case "long-text":
      return "Long Text";
    case "short-text":
      return "Short Text";
    case "number":
      return "Number";
    case "date":
      return "Date";
    case "welcome-screen":
      return "Welcome Screen";
    case "statement":
      return "Statement";
    case "end-screen":
      return "End Screen";
    case "redirect-to-url":
      return "Redirect to URL";
    default:
      return "Unknown";
  }
}

export const countryCodes: CountryCode[] = [
  { code: "+1", name: "United States", flag: "🇺🇸" },
  { code: "+1", name: "Canada", flag: "🇨🇦" },
  { code: "+44", name: "United Kingdom", flag: "🇬🇧" },
  { code: "+91", name: "India", flag: "🇮🇳" },
  { code: "+61", name: "Australia", flag: "🇦🇺" },
  { code: "+387", name: "Bosnia and Herzegovina", flag: "🇧🇦" },
  { code: "+81", name: "Japan", flag: "🇯🇵" },
  { code: "+49", name: "Germany", flag: "🇩🇪" },
  { code: "+33", name: "France", flag: "🇫🇷" },
  { code: "+86", name: "China", flag: "🇨🇳" },
  { code: "+39", name: "Italy", flag: "🇮🇹" },
  { code: "+34", name: "Spain", flag: "🇪🇸" },
  { code: "+55", name: "Brazil", flag: "🇧🇷" },
  { code: "+7", name: "Russia", flag: "🇷🇺" },
  { code: "+27", name: "South Africa", flag: "🇿🇦" },
  { code: "+34", name: "Argentina", flag: "🇦🇷" },
  { code: "+47", name: "Norway", flag: "🇳🇴" },
  { code: "+46", name: "Sweden", flag: "🇸🇪" },
  { code: "+41", name: "Switzerland", flag: "🇨🇭" },
  { code: "+31", name: "Netherlands", flag: "🇳🇱" },
  { code: "+32", name: "Belgium", flag: "🇧🇪" },
  { code: "+351", name: "Portugal", flag: "🇵🇹" },
  { code: "+30", name: "Greece", flag: "🇬🇷" },
  { code: "+61", name: "New Zealand", flag: "🇳🇿" },
  { code: "+34", name: "Mexico", flag: "🇲🇽" },
  { code: "+52", name: "Chile", flag: "🇨🇱" },
  { code: "+90", name: "Turkey", flag: "🇹🇷" },
  { code: "+48", name: "Poland", flag: "🇵🇱" },
  { code: "+372", name: "Estonia", flag: "🇪🇪" },
  { code: "+420", name: "Czech Republic", flag: "🇨🇿" },
  { code: "+36", name: "Hungary", flag: "🇭🇺" },
  { code: "+373", name: "Moldova", flag: "🇲🇩" },
  { code: "+61", name: "Fiji", flag: "🇫🇯" },
  { code: "+63", name: "Philippines", flag: "🇵🇭" },
  { code: "+64", name: "New Zealand", flag: "🇳🇿" },
  { code: "+65", name: "Singapore", flag: "🇸🇬" },
  { code: "+66", name: "Thailand", flag: "🇹🇭" },
  { code: "+84", name: "Vietnam", flag: "🇻🇳" },
  { code: "+92", name: "Pakistan", flag: "🇵🇰" },
  { code: "+880", name: "Bangladesh", flag: "🇧🇩" },
  { code: "+960", name: "Maldives", flag: "🇲🇻" },
  { code: "+20", name: "Egypt", flag: "🇪🇬" },
  { code: "+212", name: "Morocco", flag: "🇲🇦" },
  { code: "+213", name: "Algeria", flag: "🇩🇿" },
  { code: "+216", name: "Tunisia", flag: "🇹🇳" },
  { code: "+218", name: "Libya", flag: "🇱🇾" },
  { code: "+234", name: "Nigeria", flag: "🇳🇬" },
  { code: "+254", name: "Kenya", flag: "🇰🇪" },
  { code: "+256", name: "Uganda", flag: "🇺🇬" },
  { code: "+260", name: "Zambia", flag: "🇿🇲" },
  { code: "+263", name: "Zimbabwe", flag: "🇿🇼" },
  { code: "+27", name: "South Africa", flag: "🇿🇦" },
  { code: "+211", name: "South Sudan", flag: "🇸🇸" },
  { code: "+221", name: "Senegal", flag: "🇸🇳" },
  { code: "+223", name: "Mali", flag: "🇲🇱" },
  { code: "+225", name: "Ivory Coast", flag: "🇨🇮" },
  { code: "+230", name: "Mauritius", flag: "🇲🇺" },
  { code: "+233", name: "Ghana", flag: "🇬🇭" },
  { code: "+34", name: "Spain", flag: "🇪🇸" },
  { code: "+351", name: "Portugal", flag: "🇵🇹" },
  { code: "+39", name: "Italy", flag: "🇮🇹" },
  { code: "+33", name: "France", flag: "🇫🇷" },
  { code: "+49", name: "Germany", flag: "🇩🇪" },
  { code: "+32", name: "Belgium", flag: "🇧🇪" },
  { code: "+31", name: "Netherlands", flag: "🇳🇱" },
  { code: "+30", name: "Greece", flag: "🇬🇷" },
  { code: "+353", name: "Ireland", flag: "🇮🇪" },
  { code: "+358", name: "Finland", flag: "🇫🇮" },
  { code: "+420", name: "Czech Republic", flag: "🇨🇿" },
  { code: "+48", name: "Poland", flag: "🇵🇱" },
  { code: "+36", name: "Hungary", flag: "🇭🇺" },
  { code: "+371", name: "Latvia", flag: "🇱🇻" },
  { code: "+372", name: "Estonia", flag: "🇪🇪" },
  { code: "+370", name: "Lithuania", flag: "🇱🇹" },
  { code: "+351", name: "Portugal", flag: "🇵🇹" },
  { code: "+40", name: "Romania", flag: "🇷🇴" },
  { code: "+41", name: "Switzerland", flag: "🇨🇭" },
  { code: "+43", name: "Austria", flag: "🇦🇹" },
  { code: "+421", name: "Slovakia", flag: "🇸🇰" },
  { code: "+386", name: "Slovenia", flag: "🇸🇮" },
  { code: "+385", name: "Croatia", flag: "🇭🇷" },
  { code: "+420", name: "Czech Republic", flag: "🇨🇿" },
  { code: "+32", name: "Belgium", flag: "🇧🇪" },
  { code: "+36", name: "Hungary", flag: "🇭🇺" },
  { code: "+372", name: "Estonia", flag: "🇪🇪" },
  { code: "+46", name: "Sweden", flag: "🇸🇪" },
  { code: "+47", name: "Norway", flag: "🇳🇴" },
  { code: "+45", name: "Denmark", flag: "🇩🇰" },
  { code: "+44", name: "United Kingdom", flag: "🇬🇧" },
  { code: "+375", name: "Belarus", flag: "🇧🇾" },
  { code: "+380", name: "Ukraine", flag: "🇺🇦" },
  { code: "+7", name: "Russia", flag: "🇷🇺" },
  { code: "+90", name: "Turkey", flag: "🇹🇷" },
  { code: "+66", name: "Thailand", flag: "🇹🇭" },
  { code: "+84", name: "Vietnam", flag: "🇻🇳" },
  { code: "+92", name: "Pakistan", flag: "🇵🇰" },
  { code: "+880", name: "Bangladesh", flag: "🇧🇩" },
  { code: "+960", name: "Maldives", flag: "🇲🇻" },
  { code: "+91", name: "India", flag: "🇮🇳" },
  { code: "+86", name: "China", flag: "🇨🇳" },
  { code: "+81", name: "Japan", flag: "🇯🇵" },
  { code: "+82", name: "South Korea", flag: "🇰🇷" },
  { code: "+60", name: "Malaysia", flag: "🇲🇾" },
  { code: "+65", name: "Singapore", flag: "🇸🇬" },
  { code: "+61", name: "Australia", flag: "🇦🇺" },
  { code: "+64", name: "New Zealand", flag: "🇳🇿" },
  { code: "+55", name: "Brazil", flag: "🇧🇷" },
  { code: "+56", name: "Chile", flag: "🇨🇱" },
  { code: "+54", name: "Argentina", flag: "🇦🇷" },
  { code: "+52", name: "Mexico", flag: "🇲🇽" },
  { code: "+53", name: "Cuba", flag: "🇨🇺" },
  { code: "+57", name: "Colombia", flag: "🇨🇴" },
  { code: "+58", name: "Venezuela", flag: "🇻🇪" },
  { code: "+51", name: "Peru", flag: "🇵🇪" },
  { code: "+591", name: "Bolivia", flag: "🇧🇴" },
  { code: "+598", name: "Uruguay", flag: "🇺🇾" },
  { code: "+597", name: "Suriname", flag: "🇸🇷" },
  { code: "+592", name: "Guyana", flag: "🇬🇾" },
  { code: "+501", name: "Belize", flag: "🇧🇿" },
  { code: "+502", name: "Guatemala", flag: "🇬🇹" },
  { code: "+503", name: "El Salvador", flag: "🇸🇻" },
  { code: "+504", name: "Honduras", flag: "🇭🇳" },
  { code: "+505", name: "Nicaragua", flag: "🇳🇮" },
  { code: "+506", name: "Costa Rica", flag: "🇨🇷" },
  { code: "+507", name: "Panama", flag: "🇵🇦" },
  { code: "+509", name: "Haiti", flag: "🇭🇹" },
  { code: "+1-758", name: "Saint Lucia", flag: "🇱🇨" },
  { code: "+1-784", name: "Saint Vincent and the Grenadines", flag: "🇻🇨" },
  { code: "+1-868", name: "Trinidad and Tobago", flag: "🇹🇹" },
  { code: "+1-876", name: "Jamaica", flag: "🇯🇲" },
  { code: "+1-340", name: "United States Virgin Islands", flag: "🇻🇮" },
];
