import { PATHS } from "@/common/routes";
import { toggleIsEditor } from "@/services/redux/slices/editorSlice";
import { AppDispatch, RootState } from "@/services/redux/store";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator } from "./ui/breadcrumb";
import { Button } from "./ui/button";
import { Card } from "./ui/card";
import { Label } from "./ui/label";
import { Logo } from "./ui/logo";
import { Switch } from "./ui/switch";

interface NavbarProps {
  location: string;
}

export default function Navbar({ location }: NavbarProps) {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const selectedFormId = useSelector((state: RootState) => state.selectedFormId.formId);
  const isEditor = useSelector((state: RootState) => state.editor.isEditor);

  const baseCreatePath = `${PATHS.CREATE}/${selectedFormId}`;
  const previewPath = `${baseCreatePath}${PATHS.PREVIEW}`;
  const resultsPath = `${baseCreatePath}${PATHS.RESULTS}`;

  const determineVariant = (location: string, path: string) => {
    return location === path ? "secondary" : "ghost";
  };

  const handleKeyDown = (event: { key: string }) => {
    if (event.key === "Enter" || event.key === " ") {
      navigate(PATHS.HOME);
    }
  };

  return location.startsWith(PATHS.CREATE) ? (
    <Card className="p-2 flex items-center justify-between min-h-14 max-h-14">
      <div className="flex items-center gap-2">
        <div role="button" tabIndex={0} onKeyDown={handleKeyDown} onClick={() => navigate(PATHS.HOME)} className="cursor-pointer">
          <Logo />
        </div>
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem onClick={() => navigate(PATHS.HOME)}>
              <BreadcrumbLink>My Workspace</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>My Form</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>
      <div className="flex-grow flex justify-center items-center space-x-1">
        <Button
          variant={determineVariant(location, baseCreatePath)}
          onClick={() => navigate(!selectedFormId ? PATHS.HOME : baseCreatePath)}>
          Create
        </Button>
        <Button
          variant={determineVariant(location, previewPath)}
          onClick={() => navigate(!selectedFormId ? PATHS.HOME : previewPath)}>
          Preview
        </Button>
        <Button
          variant={determineVariant(location, resultsPath)}
          onClick={() => navigate(!selectedFormId ? PATHS.HOME : resultsPath)}>
          Results
        </Button>
      </div>

      <div className="flex items-center gap-2">
        <div className="flex items-center space-x-2">
          <Switch id="editor-mode" checked={isEditor} onCheckedChange={() => dispatch(toggleIsEditor())} />
          <Label htmlFor="editor-mode">Editor Mode</Label>
        </div>
        <Button size={"sm"} variant={"outline"}>
          Share
        </Button>
        <Button size={"sm"}>Publish</Button>
        <Avatar className="h-8 w-8">
          <AvatarImage src="https://github.com/shadcn.png" />
          <AvatarFallback>LF</AvatarFallback>
        </Avatar>
      </div>
    </Card>
  ) : (
    <Card className="p-2 flex items-center justify-between min-h-14 max-h-14">
      <div role="button" tabIndex={0} onKeyDown={handleKeyDown} onClick={() => navigate(PATHS.HOME)} className="cursor-pointer">
        <Logo />
      </div>
      <div className="flex-grow flex justify-center items-center space-x-1">
        <Button variant={location === PATHS.HOME ? "secondary" : "ghost"} onClick={() => navigate(PATHS.HOME)}>
          Workspace
        </Button>
        <Button variant={location === PATHS.INTEGRATIONS ? "secondary" : "ghost"} onClick={() => navigate(PATHS.INTEGRATIONS)}>
          Integrations
        </Button>
      </div>
      <div className="flex items-center gap-2">
        <Button size={"sm"}>Create Form</Button>
        <Avatar className="h-8 w-8">
          <AvatarImage src="https://github.com/shadcn.png" />
          <AvatarFallback>LF</AvatarFallback>
        </Avatar>
      </div>
    </Card>
  );
}
