import { Form } from "@/services/redux/slices/allFormsSlice";

export const getAllForms: Form[] = [
  {
    id: 1,
    title: "Customer Satisfaction Survey",
    responses: 3,
    completion: 0.33,
    updated: new Date().toLocaleString(),
    created: new Date().toLocaleString(),
  },
  {
    id: 2,
    title: "Employee Feedback Form",
    responses: 0,
    completion: 0,
    updated: new Date().toLocaleString(),
    created: new Date().toLocaleString(),
  },
];

export const fetchAllFormsAPI = async (): Promise<Form[]> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(getAllForms);
    }, 1000); // Simulate network delay
  });
};
