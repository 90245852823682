import React, { Suspense } from "react";
import { Route, HashRouter as Router, Routes } from "react-router-dom";
import { ROUTES } from "../../common/routes";

const AppRouter: React.FC = () => {
  return (
    <Router>
      <Suspense fallback={<div>Loading application...</div>}>
        <Routes>
          {ROUTES.map((route, index) => (
            <Route key={index} path={route.path} element={route.element}>
              {route.children?.map((childrenRoute, childIndex) => (
                <Route key={childIndex} path={childrenRoute.path} element={childrenRoute.element} />
              ))}
            </Route>
          ))}
        </Routes>
      </Suspense>
    </Router>
  );
};

export default AppRouter;
