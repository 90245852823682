import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SelectedFormIdState {
  formId: string | null;
}

const initialState: SelectedFormIdState = {
  formId: null,
};

const selectedFormIdSlice = createSlice({
  name: "selectedForm",
  initialState,
  reducers: {
    selectForm(state, action: PayloadAction<string>) {
      state.formId = action.payload;
    },
    clearSelectedForm(state) {
      state.formId = null;
    },
  },
});

export const { selectForm, clearSelectedForm } = selectedFormIdSlice.actions;

export default selectedFormIdSlice.reducer;
