import { configureStore } from "@reduxjs/toolkit";
import allFormsReducer from "./slices/allFormsSlice";
import editorReducer from "./slices/editorSlice";
import selectedFormContentReducer from "./slices/selectedFormContentSlice";
import selectedFormIdReducer from "./slices/selectedFormIdSlice";

export const store = configureStore({
  reducer: {
    allForms: allFormsReducer,
    selectedFormId: selectedFormIdReducer,
    selectedFormContent: selectedFormContentReducer,
    editor: editorReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
