import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface EditorState {
  isEditor: boolean;
}

const initialState: EditorState = {
  isEditor: true, // Set the default state
};

const editorSlice = createSlice({
  name: "editor",
  initialState,
  reducers: {
    setIsEditor(state, action: PayloadAction<boolean>) {
      state.isEditor = action.payload;
    },
    toggleIsEditor(state) {
      state.isEditor = !state.isEditor;
    },
  },
});

export const { setIsEditor, toggleIsEditor } = editorSlice.actions;

export default editorSlice.reducer;
