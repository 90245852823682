import Layout from "@/layout/Layout";
import { lazy, ReactNode, Suspense } from "react";

const Home = lazy(() => import("../pages/home/home"));
const Editor = lazy(() => import("../pages/editor/editor"));
const FormResults = lazy(() => import("../pages/formResults/formResults"));

export const PATHS = {
  HOME: "/",
  CREATE: "/create",
  PREVIEW: "/preview",
  RESULTS: "/results",
  INTEGRATIONS: "/integrations",
};

export interface RouteConfig {
  path: string;
  element: ReactNode;
  children?: RouteConfig[];
}

export const ROUTES: RouteConfig[] = [
  {
    path: "/",
    element: (
      <Suspense fallback={<div>Loading layout...</div>}>
        <Layout />
      </Suspense>
    ),
    children: [
      {
        path: PATHS.HOME,
        element: (
          <Suspense fallback={<div>Loading home...</div>}>
            <Home />
          </Suspense>
        ),
      },
      {
        path: `${PATHS.CREATE}/:formId`,
        element: (
          <Suspense fallback={<div>Loading editor...</div>}>
            <Editor />
          </Suspense>
        ),
      },
      {
        path: `${PATHS.CREATE}/:formId${PATHS.PREVIEW}`,
        element: (
          <Suspense fallback={<div>Loading preview...</div>}>
            <div>Preview</div>
          </Suspense>
        ),
      },
      {
        path: `${PATHS.CREATE}/:formId${PATHS.RESULTS}`,
        element: (
          <Suspense fallback={<div>Loading results...</div>}>
            <FormResults />
          </Suspense>
        ),
      },
      {
        path: PATHS.INTEGRATIONS,
        element: (
          <Suspense fallback={<div>Loading Integrations...</div>}>
            <div>Integrations</div>
          </Suspense>
        ),
      },
    ],
  },
];
